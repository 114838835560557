import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import WorkGrid from "../components/work-grid"
import ImageGridItem from "../components/image-grid-item"
import CenteredSlider from "../components/centered-slider"
import CenteredSlide from "../components/centered-slide"
import Spacer from "../components/spacer"
import Testimonials from "../components/testimonials"
import ShopifyBanner from "../components/shopify-banner"

const IndexPage = ({ siteTitle, data }) => {

    // const posts = this.props.data.allWordpressPost
    // const page = this.props.data.wordpressPage

    return (
      <Layout>
        <SEO title="Website design and development Bournemouth, Poole and Christchurch" />

        <Hero 
          video="ocean.mp4"
          // image="hero.jpg"
          title="Helping you to tell your story"
          subtitle="Old Salt was designed to deliver value and exceptional results for clients of all sizes. We're driven by bringing ideas to life and working with great people."
          link="/services"
        />

        <div className="row align-middle shrink-width gm-top--1 gm-bottom--1 relative">
          <div className="column small-12 medium-6 medium-text-left text-center">
            <h3 className="no-margin-bottom button-title">Our Work</h3>
            <p className="gm-bottom--1">Below is a selection of some of the work that we've produced here at Old Salt.</p>
          </div>
          <div className="column small-12 medium-6 text-center medium-text-right">
            <Link to="/work" className="button no-margin-bottom">View work</Link>
          </div>
        </div>
        <WorkGrid>
          <ImageGridItem width={7} image={ '../sarrieri.jpg' } subtitle="Shopify" title="Sarrieri" link="https://sarrieri.com/" showLink="true" />
          <ImageGridItem width={5} image={ '../performance-comms.jpg' } subtitle="WordPress" title="Performance Communications" link="https://performancecomms.com/" showLink="true" />
          <ImageGridItem width={5} image={ '../tc24.jpg' } subtitle="WooCommerce" title="Telecare24" link="https://www.telecare24.co.uk/" showLink="true" />      
          <ImageGridItem width={7} image={ '../grounded.jpg' } subtitle="Shopify" title="Grounded Coffee" link="https://www.grounded-coffee.com/" showLink="true" />          
          <ImageGridItem width={7} image={ '../social-game.jpg' } subtitle="Shopify" title="The Social Game" link="https://thesocialgame.co.uk/" showLink="true" />      
          <ImageGridItem width={5} image={ '../envista.jpg' } subtitle="Shopify" title="Envista" link="https://envista.co.uk/" showLink="true" />      
          <ImageGridItem width={5} image={ '../performance-comms.jpg' } subtitle="WordPress" title="Performance Communications" link="https://performancecomms.com/" showLink="true" />      
          <ImageGridItem width={7} image={ '../first-wealth.jpg' } subtitle="WordPress" title="First Wealth" link="https://www.firstwealth.co.uk/" showLink="true" />
        </WorkGrid>          

        <Spacer space="4" />

        <div className="row align-center">

          <div className="column small-12 large-8">
            <div className="row align-center text-center">
              <div className="column small-12 large-10">
                <h2 className="gm-bottom--1" style={{ lineHeight: 1 }}>About us</h2>  
                <p>My name is Steve Coates and I'm a freelance frontend web developer and designer based in Bournemouth, Dorset. I've worked in digital for nearly 15 years and, in that time, I have gained a wealth of experience and technical knowledge.</p>
                <p>Having previously worked at a number of agencies in the Bournemouth / Poole area and now as a freelancer since 2019, I've had to opportunities to work on a diverse range or projects from small startups to global brands.</p>
              </div>
              <div className="column small-12 text-center gm-top--1">
                <Link to="/services" className="button no-margin-bottom">View services</Link>
              </div>
            </div>
          </div>
        </div>

        <Spacer space="4" />

        <Testimonials />

        <Spacer space="4" />

        <div className="row align-middle shrink-width gm-top--1 gm-bottom--1">
          <div className="column small-12  text-center">
            <h3 className="no-margin-bottom button-title">Brands we've worked with</h3>
            <p className="gm-bottom--1">We've been fortunate to work with some incredible brands over the years</p>
          </div>
        </div>

        <div className="row small-up-2 medium-up-3 large-up-6">
          <div data-aos="fade-right" className="column gm-bottom--1">
            <img src="jackal-logo.png" alt="The Jackal Magazine" />
          </div>
          <div data-aos="fade-right" data-aos-delay="50" className="column gm-bottom--1">
            <img src="finisterre.png" alt="Finisterre" />
          </div>
          <div data-aos="fade-right" data-aos-delay="100" className="column gm-bottom--1">
            <img src="osprey.png" alt="Osprey" />
          </div>  
          <div data-aos="fade-right" data-aos-delay="150" className="column gm-bottom--1">
            <img src="paco-rabanne.png" alt="Paco Rabanne" />
          </div>
          <div data-aos="fade-right" data-aos-delay="200" className="column gm-bottom--1">
            <img src="wedgwood.png" alt="Wedgwood" />
          </div>
          <div data-aos="fade-right" data-aos-delay="250" className="column gm-bottom--1">
            <img src="orla-kiely.png" alt="Orla Kiely" />
          </div>
        </div>  

        {/*<CenteredSlider>
            {posts.edges.map(({ node }) => (
              <CenteredSlide key={node.id} title={node.title} image={ node.featured_media.localFile.childImageSharp.original.src } link={node.path} />
            ))}
          </CenteredSlider>*/}

        <Spacer space="4" />
        <ShopifyBanner />


      </Layout>
    )
  }

export default IndexPage

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: {fields: [date], order: DESC}) {
      edges {
        node {
          id
          slug
          path
          title
          featured_media {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }        
        }
      }
    }
  }
`