import { Link } from "gatsby"
import React, { useEffect } from "react"
import PropTypes from "prop-types"
import AOS from 'aos';
import 'aos/dist/aos.css';

const Hero = ({ image, title, subtitle, link, video }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="row infinite-row hero hero--overlap align-middle" style={{ backgroundImage:`url(${image})` }}>
      <div className="video-holder">
        <video autoPlay playsInline muted loop src={video}></video>
      </div>
      <div className="hero__content column small-12 medium-10 medium-offset-1 large-8 large-offset-2 text-center">
        <h1 data-aos="fade-up">{ title }</h1>
        <div className="row align-center">
          <div className="column small-12 medium-10 large-8">
            <p data-aos="fade-up" data-aos-delay="200" className="p--large gm-bottom--2">{ subtitle }</p>
          </div>
        </div>
        <Link data-aos="fade-in" data-aos-delay="500" data-aos-duration="900" to={ link } className="button">Read more</Link>
      </div>
    </div>
  )
}

Hero.propTypes = {
  image: PropTypes.string,
  link: PropTypes.string,
}

Hero.defaultProps = {
  image: ``,
  title: ``,
  subtitle: ``,
  link: ``,
}

export default Hero